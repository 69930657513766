<template>
  <div class="list">
    <template v-if="loading && book">
      <div class="list-book">
        <div class="list-book-cover">
          <van-image
            fit="contain"
            :src="
              book.coverPicUrl == ''
                ? 'https://53english.cdn.53online.cn/bookCover.png'
                : book.coverPicUrl
            "
          />
        </div>
        <div class="list-book-flex">
          <div class="title" v-html="book.bookName"></div>
          <div v-if="book.allResUrl" @click="tapPopup" class="down">
            下载整书资源
          </div>
          <!-- <div class="down1"></div> -->
        </div>
      </div>
      <dl class="list-type">
        <dt>{{ book.catalogName }}</dt>
        <dd v-if="list.length > 0">
          <template v-for="item in list">
            <router-link
              v-if="item.resFileUrl && item.dataType == 1"
              :key="item.id"
              :to="{
                name: 'audiodetail',
                query: { id: item.id },
              }"
            >
              <van-image
                fit="contain"
                :src="
                  item.dataType == 1 && item.hasFollowRead == 1
                    ? 'https://53english.cdn.53online.cn/%E8%B7%9F%E8%AF%BB.png'
                    : 'https://53english.cdn.53online.cn/%E5%90%AC%E5%8A%9B.png'
                "
              />
              <span>{{ item.name }}</span></router-link
            >
            <!--pdf-->
            <router-link
              v-else-if="item.resFileUrl && item.dataType == 2"
              :key="item.id"
              :to="{
                name: 'pdfdetail',
                query: { id: item.id },
              }"
            >
              <van-image
                fit="contain"
                src="https://res.cdn.quyixian.com/front/pdf-b.png"
              />
              <span>{{ item.name }}</span>
            </router-link>
            <div class="dd" @click="tapMes" v-else :key="item.id">
              <van-image fit="contain" v-if="item.dataType == 2"
              src="https://res.cdn.quyixian.com/front/pdf-b.png"" />
              <van-image
                fit="contain"
                v-else
                :src="
                  item.dataType == 1 && item.hasFollowRead == 1
                    ? 'https://53english.cdn.53online.cn/%E8%B7%9F%E8%AF%BB.png'
                    : 'https://53english.cdn.53online.cn/%E5%90%AC%E5%8A%9B.png'
                "
              />
              <span>{{ item.name }}</span>
            </div>
          </template>
        </dd>
      </dl>
    </template>
    <van-loading v-else vertical type="spinner">{{ loadingText }}</van-loading>
    <van-popup v-model="downShow" style="width: 90%" closeable>
      <div class="downpopup" v-if="loading && book">
        <div class="downpopup-title">
          {{ book.bookName }}
        </div>
        <div class="downpopup-bottom">
          <div class="downpopup-btn">
            <span v-if="isIos" @click="downFile(book.allResUrl)" class="down"
              >下载</span
            >
            <span class="down down2" v-if="isWxBrowser && !isIos"
              >点击右上角···选择默认浏览器打开</span
            >
            <template v-else>
              <span v-if="isAnd" @click="downFile(book.allResUrl)" class="down"
                >下载</span
              >
            </template>
          </div>
          <div class="downpopup-demo">
            <span v-if="isIos" class="down1" @click="isIosShow = true"
              >苹果手机下载后如何查看资源</span
            >
            <!-- <span v-if="isAnd" class="down1" @click="isAndShow = false"
              >安卓手机下载后如何查看资源</span
            > -->
          </div>
        </div>
        <div class="downpopup-mail">
          <van-field v-model="mail" placeholder="输入邮箱" />
          <van-button class="down" @click="tapMail">下载至邮箱</van-button>
        </div>
      </div>
    </van-popup>
    <van-image-preview v-model="isIosShow" :images="iosList" />
    <van-image-preview v-model="isAndShow" :images="andList" />
    <div class="offset" @click="$router.back()">
      <van-icon name="arrow-left" />
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
export default {
  name: "listtype",
  data() {
    return {
      loading: false,
      loadingText: "加载中...",
      // fid:
      //   this.$route.query.fid == undefined
      //     ? "625325628891074560"
      //     : this.$route.query.fid,
      fid: this.$route.query.fid,
      list: [],
      book: null,
      title: "53英语听力突破七年级突破七年级53英语听力突破七年级突破七年级",
      downShow: false,
      mail: "",
      isIos: false,
      isIosShow: false,
      iosList: [
        "https://53english.cdn.53online.cn/ios/1.png",
        "https://53english.cdn.53online.cn/ios/2.png",
        "https://53english.cdn.53online.cn/ios/3.png",
        "https://53english.cdn.53online.cn/ios/4.png",
        "https://53english.cdn.53online.cn/ios/5.png",
        "https://53english.cdn.53online.cn/ios/6.png",
        "https://53english.cdn.53online.cn/ios/7.png",
      ],
      isAnd: false,
      isAndShow: false,
      andList: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg",
      ],
      isWxBrowser: false,
    };
  },
  mounted() {
    this.getResList();
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isIos = /iphone|ipad|ipod/.test(userAgent);
    this.isAnd = /android/.test(userAgent);
    this.isWxBrowser = /micromessenger/.test(userAgent);
    // if (this.isWxBrowser) {
    //   let url = window.location.href + "&down=true";
    //   window.history.replaceState(
    //     {
    //       path: url,
    //     },
    //     "",
    //     url
    //   );
    // }
  },
  methods: {
    downFile(url) {
      window.location.href = url;
    },
    getResList() {
      this.$api.res
        .getResList({ fid: this.fid })
        .then(({ tag, data, message }) => {
          if (tag == 1) {
            this.loading = true;
            this.book = data;
            this.list = data.resList;
            console.log(this.list);
          } else {
            //获取数据失败
            Dialog.alert({ title: "获取数据失败", message: message }).then(
              () => {
                this.loadingText = "页面出现异常，请重新进入！";
                //点击事件
              }
            );
          }
        });
    },
    tapMes() {
      Toast("资源待更新");
    },
    tapPopup() {
      this.downShow = !this.downShow;
      if (this.downShow == true && this.isWxBrowser) {
        let params = new URLSearchParams(window.location.search);
        let url = window.location.href;
        let down = params.get("down");
        if (down != "true") {
          url = window.location.href + "&down=true";
        }
        window.history.replaceState(
          {
            path: url,
          },
          "",
          url
        );
      }
    },
    tapMail() {
      if (this.isValidEmail(this.mail)) {
        let params = {
          BookName: this.book.bookName,
          AllResUrl: this.book.allResUrl,
          Email: this.mail,
        };
        this.$api.res.sendEmail(params).then(({ tag }) => {
          if (tag == 1) {
            Toast("邮件发送成功");
            this.downShow = false;
          } else {
            Toast("邮件发送失败");
          }
        });
      } else {
        Toast("请输入正确的邮箱");
      }
    },
    isValidEmail(email) {
      // 使用正则表达式判断邮箱格式
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    },
  },
};
</script>

<style></style>
